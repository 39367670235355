* {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

input[type="text"], input[type="password"] {
  width: 100%;
}

.app {
  justify-content: flex-end;
  display: flex;
}

.app > * {
  width: 600px;
  max-height: 100vh;
  border: 1px solid #ccc;
  margin: 8px;
  padding: 10px;
  overflow-y: auto;
}

h2 {
  margin: 0 0 .6em;
  font-size: 1.3em;
  font-weight: 400;
  display: inline-block;
}

.merchant textarea {
  width: 100%;
  min-height: 300px;
  resize: vertical;
  font-family: monospace;
}

.merchant label {
  margin-bottom: .4em;
  font-size: .8em;
  display: block;
}

.merchant input {
  margin-bottom: 1em;
}

.merchant button {
  margin: 4px 8px 8px 0;
}

.merchant .callbacks .message {
  white-space: pre-wrap;
  font-family: monospace;
}

.merchant .auth > label {
  margin-bottom: .5em;
}

.merchant .auth > div {
  display: flex;
}

.merchant .auth > div > div {
  flex-grow: 1;
}

.merchant .auth div:nth-child(1) {
  margin-right: 1em;
}

.custom-theme {
  margin-bottom: 8px;
}

.sdk-flex-wrapper {
  flex-grow: 1;
}

.sdk {
  max-width: 600px;
  margin: 0 auto;
}

.sdk button {
  margin: 4px 0 8px;
}

.sdk .dimmed {
  color: #aaa;
  margin: 3px 0;
  font-size: .8em;
  display: inline-block;
}

.sdk select {
  margin: 0 0 2px;
}

.sdk label {
  margin-bottom: .4em;
  font-size: .8em;
  display: block;
}

.sdk .action-button {
  margin-bottom: 20px;
}

.sdk .iframe-placeholder {
  color: gray;
  min-height: 200px;
  border: 3px dashed gray;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  display: flex;
}

.console {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.console .clear-button {
  float: right;
  margin-top: 4px;
}

.console li {
  border-bottom: 1px dashed #d3d3d3;
  padding: .2em 0;
  list-style-type: none;
}

.console li:hover {
  background: #f8f8f8;
}

.console li.error {
  color: #d00000;
  background: snow;
}

.console li.error:hover {
  background: #ffe6e6;
}

.console li.error a {
  color: #d00000;
}

.console li .event-label {
  float: right;
}

@media only screen and (max-width: 1200px) {
  .app {
    flex-wrap: wrap;
    justify-content: center;
  }

  .sdk-flex-wrapper, .console {
    max-height: none;
  }
}

/*# sourceMappingURL=index.415c4e38.css.map */

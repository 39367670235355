* {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

input[type='text'],
input[type='password'] {
  width: 100%;
}

.app {
  display: flex;
  justify-content: flex-end;
}

.app > * {
  width: 600px;
  padding: 10px;
  margin: 8px;
  max-height: 100vh;
  overflow-y: auto;
  border: 1px solid #ccc;
}

h2 {
  font-weight: 400;
  font-size: 1.3em;
  margin: 0 0 0.6em 0;
  display: inline-block;
}

/* Merchant */

.merchant textarea {
  font-family: monospace;
  width: 100%;
  min-height: 300px;
  resize: vertical;
}

.merchant label {
  display: block;
  font-size: 0.8em;
  margin-bottom: 0.4em;
}

.merchant input {
  margin-bottom: 1em;
}

.merchant button {
  margin: 4px 8px 8px 0;
}

.merchant .callbacks .message {
  font-family: monospace;
  white-space: pre-wrap;
}

.merchant .auth > label {
  margin-bottom: 0.5em;
}
.merchant .auth > div {
  display: flex;
}

.merchant .auth > div > div {
  flex-grow: 1;
}

.merchant .auth div:nth-child(1) {
  margin-right: 1em;
}

.custom-theme {
  margin-bottom: 8px;
}

/* SDK */

/* 
 * Wrap the sdk component with something that grows in order to fill
 * the remaining space. The wrapper element makes the scrollbar appear
 * on the right and does not hug the webcheckout <iframe>.
 */
.sdk-flex-wrapper {
  flex-grow: 1;
}

/*
 * Make sure the webcheckout is centered and not too wide.
 */
.sdk {
  max-width: 600px;
  margin: 0 auto;
}

.sdk button {
  margin: 4px 0 8px 0;
}

.sdk .dimmed {
  display: inline-block;
  font-size: 0.8em;
  color: #aaaaaa;
  margin: 3px 0;
}

.sdk select {
  margin: 0 0 2px 0;
}

.sdk label {
  display: block;
  font-size: 0.8em;
  margin-bottom: 0.4em;
}

.sdk .action-button {
  margin-bottom: 20px;
}

.sdk .iframe-placeholder {
  border: 3px dashed gray;
  font-size: 1.3em;
  color: gray;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Console */

.console {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.console .clear-button {
  float: right;
  margin-top: 4px;
}

.console li {
  list-style-type: none;
  padding: 0.2em 0;
  border-bottom: 1px dashed lightgray;
}

.console li:hover {
  background: #f8f8f8;
}

.console li.error {
  color: #d00000;
  background: #fffafa;
}

.console li.error:hover {
  background: #ffe6e6;
}

.console li.error a {
  color: #d00000;
}

.console li .event-label {
  float: right;
}

/*
 * If the screen is too narrow, let the sdk and console wrap so that
 * they appear on top of each other. And don't restrict the max height
 * to be equal to the viewport.
 */
@media only screen and (max-width: 1200px) {
  .app {
    justify-content: center;
    flex-wrap: wrap;
  }

  .sdk-flex-wrapper {
    max-height: none;
  }

  .console {
    max-height: none;
  }
}
